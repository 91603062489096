<template>
  <v-container fluid class="mb-12">
    <v-row class="no-indentation mt-6 mobileDisplay">
      <v-col lg="0" md="0" sm="12" class="cardBlock">
        <cards-with-slogan-mobile
            :title="$t('message.companyName')"
            :sub-title="$t('message.slogan')"
            :id_title="348"
            :id_sub-title="349"
            class="borderCardMobile"/>
      </v-col>
    </v-row>
    <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10">
      <v-spacer class="cardSpacer"/>
      <v-col lg="3" md="3" sm="12" class="cardBlock">
        <cards-with-slogan
            :title="$t('message.companyName')"
            :sub-title="$t('message.slogan')"
            :id_title="348"
            :id_sub-title="349"
        />
      </v-col>
      <v-col lg="2" md="2" sm="3" class="cardBlock">
        <cards-with-numbers
                :text="$t('message.contentNumeric1')"
                big-num="25"
                small-num="000"
                symbol=" +"
                :svgPath="svgNum1"
                id_text="19"
                id_big-num="20"
                id_small-num="21"
                id_unit="22"
                id_symbol="23"
                id_svgPath="24"
        />
      </v-col>
      <v-col lg="2" md="2" sm="3" class="cardBlock">
        <cards-with-numbers
                :text="$t('message.contentNumeric2')"
                big-num="3"
                small-num="000"
                :unit="$t('message.contentUnit2')"
                symbol=" +"
                :svgPath="svgNum2"
                id_text="1"
                id_big-num="2"
                id_small-num="3"
                id_unit="4"
                id_symbol="5"
                id_svgPath="6"
        />
      </v-col>
      <v-col lg="2" md="2" sm="3" class="cardBlock">
        <cards-with-numbers
                :text="$t('message.contentNumeric3')"
                big-num="1"
                :unit="$t('message.contentUnit3')"
                symbol=" +"
                :svgPath="svgNum3"
                id_text="7"
                id_big-num="8"
                id_small-num="9"
                id_unit="10"
                id_symbol="11"
                id_svgPath="12"
        />
      </v-col>
      <v-col lg="2" md="2" sm="3" class="cardBlock">
        <cards-with-numbers
                :text="$t('message.contentNumeric4')"
                big-num="95"
                symbol=" +"
                :svgPath="svgNum4"
                id_text="13"
                id_big-num="14"
                id_small-num="15"
                id_unit="16"
                id_symbol="17"
                id_svgPath="18"
        />
      </v-col>
      <v-spacer class="cardSpacer"/>
    </v-row>
    <v-row class="no-indentation mt-8"
             v-for="content in sliders_content"
             :key="content.id_group"
    >
      <v-spacer class="cardSpacer"/>
      <v-col lg="11" md="11" sm="12" class="cardBlock">
        <slider-products :id_group="content.id_group" :name_group_en="content.name_group_en" :name_group_rus="content.name_group_rus" :products="content.products"/>
      </v-col>
      <v-spacer class="cardSpacer"/>
    </v-row>
    <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10">
      <v-spacer class="cardSpacer"/>
      <v-col lg="11" md="11" sm="11" class="cardBlock pl-lg-0 pl-md-2 pl-sm-0 cardItemNews">
        <v-btn text @click="pushAllNews()">
          {{ $t('message.allNews') }}
          <v-icon>{{ svgAllNewsBtnPath }}</v-icon>
        </v-btn>
      </v-col>
      <v-spacer class="cardSpacer"/>
    </v-row>
    <v-row  class="no-indentation mt-lg-8 mt-md-8 mt-sm-6">
      <v-spacer class="cardSpacer"/>
      <v-col v-for="value in news" lg="4" md="4" sm="12" class="cardBlock newsBlock px-lg-3 px-md-1">
        <v-spacer class="cardSpacer" :style="value.spacer"/>
        <cards-news :style="value.color"
                    v-if="lang=='ru'"
                    :header="value.title"
                    :data="getDate(value.created_view)"
                    :text="value.anons"
                    :anchor="value.anchor"
        />
        <cards-news :style="value.color"
                    v-else
                    :header="value.en_title"
                    :data="getDate(value.created_view)"
                    :text="value.en_anons"
                    :anchor="value.anchor"
        />
      </v-col>
      <v-spacer class="cardSpacer"></v-spacer>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import SliderProducts from '../components/ui/SliderProducts'
import CardsWithSlogan from '../components/ui/CardsWithSlogan'
import CardsWithSloganMobile from '../components/ui/CardsWithSloganMobile'
import CardsWithNumbers from '../components/ui/CardsWithNumbers'
import CardsNews from '../components/ui/CardsNews'
import { mdiChevronRight, mdiBasketOutline, mdiAccountMultipleOutline, mdiStore, mdiCurrencyRub } from '@mdi/js'
export default {
  props: [ 'svgPath' ],
  components: {
    SliderProducts,
    CardsWithSlogan,
    CardsWithSloganMobile,
    CardsWithNumbers,
    CardsNews },
  methods: {
    getDate (value) {
      var date = new Date(value)
      var data = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return data
    },
    pushAllNews () {
      this.$router.push('/news')
    }
  },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    news () {
      var _news = this.$store.state['news']['news']
      var color = ['background-color: #32ABD1!important;', 'background-color: #F07C53!important;', 'background-color: #FFA901!important;']
      var spacer = ['', 'flex-grow: 0.55!important;', 'flex-grow: 0.55!important;']
      return _news.map(function (value, index) {
        var new_value = value
        new_value['color'] = color[index]
        new_value['spacer'] = spacer[index]
        new_value['anchor'] = index
        return new_value
      })
    }
  },
  data () {
    this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': 3, 'offset': 0 })
    this.$store.dispatch('groups/GET_DATA', { 'filters': [], 'limit': 3, 'offset': 0 })
    return {
      sliders_content: [],
      svgAllNewsBtnPath: mdiChevronRight,
      svgNum1: mdiBasketOutline,
      svgNum2: mdiStore,
      svgNum3: mdiCurrencyRub,
      svgNum4: mdiAccountMultipleOutline
    }
  },
  watch: {
    async '$store.state.groups.data' () {
      for (let group of this.$store.state.groups['data']) {
        await this.$store.dispatch('products/SET_FILTER', { filters: [group.id] })
        this.$data.sliders_content.push({
          id_group: group.id,
          name_group_rus: group.name_rus,
          name_group_en: group.name_en,
          products: this.$store.state.products.data.map(product => {
            return { id: product.id,
              name_rus: product.name_rus,
              name_en: product.name_en,
              photo: product.photo }
          })
        })
      }
      this.$store.dispatch('products/SET_FILTER', { filters: [] })
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Официальный сайт компании ООО Фарм-Инновации, занимающейся продажей лекарств, покупкой лекарств' +
            'и хранением лекарст' },
      { name: 'title',
        content: 'Название' }
    ]
  }
}
</script>
