<template>
  <v-container fluid class="mb-12">
    <v-row class="no-indentation products">
      <v-col lg="3" md="3" sm="12" class="pt-0 products-menu">
        <products-submenu />
      </v-col>
      <v-col lg="8" md="8" sm="12" class="products-list pa-0 pb-5">
        <div v-for="item in products">
          <div v-if="lang=='ru'" class="products-list__img">
            <v-badge v-if="item.recipe"
                     :content="$t('message.productsPageProductRecipe')"
                     color="pink"
                     offset-x="94"
                     offset-y="24"
                     left
            >
              <v-img v-if="item.photo"
                     :src="item.photo"
              />
              <v-img v-else
                     src="../../public/img/no-photo.png"
              />
            </v-badge>
            <v-badge v-else
                     :content="$t('message.productsPageProductNoRecipe')"
                     color="green"
                     offset-x="99"
                     offset-y="24"
                     left
            >
              <v-img v-if="item.photo"
                     :src="item.photo"
              />
              <v-img v-else
                     src="../../public/img/no-photo.png"
              />
            </v-badge>
          </div>
          <div v-else class="products-list__img">
            <v-badge v-if="item.recipe"
                     content="On prescription"
                     color="pink"
                     offset-x="127"
                     offset-y="24"
                     left
            >
              <v-img v-if="item.photo"
                     :src="item.photo"
              />
              <v-img v-else
                     src="../../public/img/no-photo.png"
              />
            </v-badge>
            <v-badge v-else
                     content="Without recipe"
                     color="green"
                     offset-x="120"
                     offset-y="24"
                     left
            >
              <v-img v-if="item.photo"
                     :src="item.photo"
              />
              <v-img v-else
                     src="../../public/img/no-photo.png"
              />
            </v-badge>
          </div>
          <div v-if="lang=='ru'" class="products-list__text">
            <v-btn @click="getCurrentProduct(item)"
                   :ripple="false"
                   text
            >
              <h3>{{ item.name_rus }}</h3>
            </v-btn>
            <v-container fluid class="pa-0">
              <v-row class="no-indentation">
                <v-col lg="8" md="12" sm="12" class="pa-0 text-container">
                  <v-container fluid class="pa-0">
                    <v-row class="no-indentation">
                      <v-col lg="4" md="6" sm="6" class="pa-0 products-list__label">
                        <div>Действующее вещество (МНН)</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 products-list__value">
                        <div>{{ item.name_mnn_rus }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                      <v-col lg="4" md="6" sm="6" class="pa-0 products-list__label">
                        <div>Производитель</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 products-list__value">
                        <div>{{ item.manufacturer_rus }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                      <v-col lg="4" md="6" sm="6" class="pa-0 products-list__label">
                        <div>Категории</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 products-list__value">
                        <div>{{ categories_str_rus(item) }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                      <v-col lg="4" md="6" sm="6" class="pa-0 products-list__label">
                        <div>Страна производства</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 mb-4 products-list__value">
                        <div>{{ item.country_rus }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col lg="4" md="12" sm="12" class="pa-0 text-right">
                  <v-btn @click="getCurrentProduct(item)"
                         class="products-list__more-btn"
                         color="cyan darken-1"
                         depressed
                  >
                    <h3>Заказать онлайн</h3>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-else class="products-list__text">
            <v-btn @click="getCurrentProduct(item)"
                   :ripple="false"
                   text
            >
              <h3>{{ item.name_en }}</h3>
            </v-btn>
            <v-container fluid class="pa-0">
              <v-row class="no-indentation">
                <v-col lg="8" md="12" sm="12" class="pa-0 text-container">
                  <v-container fluid class="pa-0">
                    <v-row class="no-indentation">
                      <v-col lg="4" md="4" sm="6" class="pa-0 products-list__label">
                        <div>Active substance (INN)</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 products-list__value">
                        <div>{{ item.name_mnn_en }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                      <v-col lg="4" md="4" sm="6" class="pa-0 products-list__label">
                        <div>Manufacturer</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 products-list__value">
                        <div>{{ item.manufacturer_en }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                      <v-col lg="4" md="4" sm="6" class="pa-0 products-list__label">
                        <div>Categories</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 products-list__value">
                        <div>{{ categories_str_en(item).categories_en }}</div>
                      </v-col>
                    </v-row>
                    <v-row class="no-indentation">
                      <v-col lg="4" md="4" sm="6" class="pa-0 products-list__label">
                        <div>Country of manufacture</div>
                      </v-col>
                      <v-col lg="8" md="6" sm="6" class="pa-0 pl-4 mb-4 products-list__value">
                        <div>{{ item.country_en }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col lg="4" md="12" sm="12" class="pa-0 text-right">
                  <v-btn @click="getCurrentProduct(item)"
                         class="products-list__more-btn"
                         color="cyan darken-1"
                         depressed
                  >
                    <h3>Order online</h3>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
        <v-pagination v-if="page_count > 1"
                      v-model="page_number"
                      @input="get_products"
                      :length="page_count"
                      class="mb-2"
                      color="cyan darken-1"
                      circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import ProductsSubmenu from '../components/ui/ProductsSubmenu'

export default {
  components: { ProductsSubmenu },
  methods: {
    getCurrentProduct (item) {
      this.$store.dispatch('products/SET_CURRENT', item)
      this.$router.push({ name: 'products_page', params: { 'id_product': item.id } })
    },
    categories_str_rus (item) {
      let res = ''
      if (Array.isArray(item['categories'])) {
        item['categories'].forEach(category => res += category['name_rus'] + ', ')
        if (res.endsWith(', ')) res = res.substr(0, res.length - 2)
      } else { res = 'У товара отсутствует категория' }
      return res
    },
    categories_str_en (item) {
      let res = ''
      if (Array.isArray(item['categories'])) {
        item['categories'].forEach(category => res += category['name_rus'] + ', ')
        if (res.endsWith(', ')) res = res.substr(0, res.length - 2)
      } else { res = 'Good does not has a category' }
      return res
    },
    get_products () {
      let _filters = []
      if (!(isNaN(this.$route.params.id_group) || this.$route.params.id_group == ' ')) { _filters = [this.$route.params.id_group] }
      this.$store.dispatch('products/SET_FILTER', {
        filters: _filters,
        limit: this.products_in_page,
        offset: this.products_in_page * (this.page_number - 1) })
      this.$store.dispatch('products/GET_DATA')
    }
  },
  data () {
    let productsInPage = 10
    if (!(isNaN(this.$route.params.id_group) || this.$route.params.id_group == ' ')) {
      this.$store.dispatch('products/SET_FILTER', {
        filters: [this.$route.params.id_group],
        limit: productsInPage,
        offset: 0 })
    } else {
      this.$store.dispatch('products/SET_FILTER', {
        filters: [],
        limit: productsInPage,
        offset: 0 })
    }
    this.$store.dispatch('products/GET_DATA')
    this.$store.dispatch('groups/GET_DATA')
    this.$store.dispatch('products/SET_QUANTITY')
    return {
      products_in_page: productsInPage,
      page_number: 1,
      key_products: this.$route.params.id_group
    }
  },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    products () {
      return this.$store.state['products']['data']
    },
    page_count () {
      let pageCount = Math.floor(this.$store.getters['products/quantity'] / this.products_in_page)
      if (this.$store.getters['products/quantity'] % this.products_in_page > 0) { pageCount += 1 }
      return pageCount
    },
    current_group () {
      return this.$store.getters['groups/get_current']
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.name === 'products') {
        this.$store.dispatch('products/SET_FILTER', { filters: [] })
        this.$store.dispatch('products/SET_QUANTITY')
        this.page_number = 1
      }
      if (to.name === 'products_with_group') {
        this.$store.dispatch('products/SET_FILTER', {
          filters: [this.$route.params.id_group],
          limit: this.products_in_page,
          offset: 0 })
        let group = this.$store.getters['groups/group_by_id'](this.$route.params.id_group)
        this.$store.dispatch('groups/SET_CURRENT', [group])
        this.$store.dispatch('products/SET_QUANTITY')
        this.page_number = 1
      }
    },
    '$store.state.groups.data' () {
      if (!(isNaN(this.$route.params.id_group) || this.$route.params.id_group == ' ')) {
        let group = this.$store.getters['groups/group_by_id'](this.$route.params.id_group)
        this.$store.dispatch('groups/SET_CURRENT', [group])
      }
    }
  },
  metaInfo () {
    let name_text = this.current_group !== undefined ? this.current_group.name_rus : 'Продукция'
    return {
      meta: [
        {
          name: 'description',
          content: 'Заказать' + name_text + 'компании Фарм-Инновации'
        },
        { name: 'title',
          content: 'Продукты' + name_text
        }
      ]
    }
  }
}
</script>