<template>
  <v-container fluid class="no-indentation" style="margin-bottom: 96px!important;">
    <v-row class="partnership">
      <about-company-submenu :toggle_none="this.toggle_exclusive"/>
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="4" md="4" sm="12" class="colManagement mt-8 mb-4 pr-6 pr-sm-6 pr-md-0 pr-lg-0 pb-0 partnership__content" >
        <content-text
            :title="$t('message.aboutUsSubmenu1')"
            :id_title="302"
            :id_sub-title="303"
            :id_content="304"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
      </v-col>
    </v-row>
    <div v-if="loadedDefaultContent && loadedAddedContent">
      <v-row v-for="(rowContent, index) in contentToShow" :key="index" class = "partnership">
        <v-col cols="12" lg="4" md="4" sm="12" class="colManagement pr-6 pr-sm-4 pr-md-3 pr-lg-3  partnership__content up">
          <card-management
                  :pathImg="rowContent[0]['pathImg']"
                  :fio="$t(rowContent[0]['fio'])"
                  :post="$t(rowContent[0]['post'])"
                  :id_path-img="rowContent[0]['id_pathImg']"
                  :id_fio="rowContent[0]['id_fio']"
                  :id_post="rowContent[0]['id_post']"
                  :hidden="rowContent[0]['hidden']"
                  :added="rowContent[0]['added']"
          />
        </v-col>
        <v-col
                v-if="rowContent.length>1"
                cols="12"
                lg="4"
                md="4"
                sm="12"
                class="colManagement pr-6 pr-sm-4 pr-md-3 pr-lg-3 partnership__content up">
          <card-management
                  :pathImg="rowContent[1]['pathImg']"
                  :fio="$t(rowContent[1]['fio'])"
                  :post="$t(rowContent[1]['post'])"
                  :id_path-img="rowContent[1]['id_pathImg']"
                  :id_fio="rowContent[1]['id_fio']"
                  :id_post="rowContent[1]['id_post']"
                  :hidden="rowContent[1]['hidden']"
                  :added="rowContent[1]['added']"
          />
        </v-col>
        <v-col
                v-if="rowContent.length>2"
                cols="12"
                lg="4"
                md="4"
                sm="12"
                class="colManagement pr-6 pr-sm-4 pr-md-3 pr-lg-3 partnership__content up">
          <card-management
                  :pathImg="rowContent[2]['pathImg']"
                  :fio="$t(rowContent[2]['fio'])"
                  :post="$t(rowContent[2]['post'])"
                  :id_path-img="rowContent[2]['id_pathImg']"
                  :id_fio="rowContent[2]['id_fio']"
                  :id_post="rowContent[2]['id_post']"
                  :hidden="rowContent[2]['hidden']"
                  :added="rowContent[2]['added']"
          />
        </v-col>
      </v-row>
    </div>
    <v-btn v-if="isAuth" @click="add_content">Добавить</v-btn>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import CardManagement from '../components/ui/CardManagement'
import ContentText from "@/components/ui/ContentText.vue";
export default {
  components: {ContentText, AboutCompanySubmenu, CardManagement },
  data () {
    let addedContent = []
    let defaultContent =
        [{
          pathImg: './img/avatar/SinghAK.png',
          fio: 'message.aboutUsManagementFIO1',
          post: 'message.aboutUsManagementPost1',
          id_pathImg: 25,
          id_fio: 26,
          id_post: 27,
          hidden: true
        },
        {
          pathImg: './img/avatar/LukyanenkoNA.png',
          fio: 'message.aboutUsManagementFIO2',
          post: 'message.aboutUsManagementPost2',
          id_pathImg: 28,
          id_fio: 29,
          id_post: 30,
          hidden: true
        },
        {
          pathImg: './img/avatar/BaykovaLL.png',
          fio: 'message.aboutUsManagementFIO3',
          post: 'message.aboutUsManagementPost3',
          id_pathImg: 31,
          id_fio: 32,
          id_post: 33,
          hidden: true
        },
        {
          pathImg: './img/avatar/StarovoytovGN.png',
          fio: 'message.aboutUsManagementFIO4',
          post: 'message.aboutUsManagementPost4',
          id_pathImg: 34,
          id_fio: 35,
          id_post: 36,
          hidden: true
        },
        {
          pathImg: './img/avatar/ShevkovaVV.png',
          fio: 'message.aboutUsManagementFIO5',
          post: 'message.aboutUsManagementPost5',
          id_pathImg: 37,
          id_fio: 38,
          id_post: 39,
          hidden: true
        },
        {
          pathImg: './img/avatar/SemeykinAV.png',
          fio: 'message.aboutUsManagementFIO6',
          post: 'message.aboutUsManagementPost6',
          id_pathImg: 40,
          id_fio: 41,
          id_post: 42,
          hidden: true
        },
        {
          pathImg: './img/avatar/SchkodinaDS.png',
          fio: 'message.aboutUsManagementFIO7',
          post: 'message.aboutUsManagementPost7',
          id_pathImg: 43,
          id_fio: 44,
          id_post: 45,
          hidden: true
        },
        {
          pathImg: './img/avatar/business-man.png',
          fio: 'message.aboutUsManagementFIO8',
          post: 'message.aboutUsManagementPost8',
          id_pathImg: 46,
          id_fio: 47,
          id_post: 48,
          hidden: true
        },
        {
          pathImg: './img/avatar/OstrouhovaNA.png',
          fio: 'message.aboutUsManagementFIO9',
          post: 'message.aboutUsManagementPost9',
          id_pathImg: 49,
          id_fio: 50,
          id_post: 51,
          hidden: true
        }]
    let letThis = this
    let managementIdList = defaultContent.map(cont => [cont['id_pathImg'], cont['id_fio'], cont['id_post']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': managementIdList })
      .then(() => {
        let hiddenContent = letThis.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          letThis.defaultContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultContent = true
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'CardManagement'
    }).then(() => {
      addedContent = letThis.$store.getters['contentChanges/added_content_by_type']('CardManagement')
      letThis.addedContent = addedContent
      letThis.loadedAddedContent = true
    })
    let contentTextIdList = [[302, 303, 304]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': contentTextIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showContentText = !hiddenContent[0]
      })
    return {
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      toggle_exclusive: 0
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let rowCont = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        rowCont.push(content)
        if (rowCont.length === 3) {
          showContent.push(rowCont)
          rowCont = []
        }
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_fio'] = this.get_field_id(content, 'fio')
        newAddedContent['id_post'] = this.get_field_id(content, 'post')
        newAddedContent['id_pathImg'] = this.get_field_id(content, 'pathImg')
        newAddedContent['added'] = true
        rowCont.push(newAddedContent)
        if (rowCont.length == 3) {
          showContent.push(rowCont)
          rowCont = []
        }
      }
      if (rowCont.length > 0) {
        showContent.push(rowCont)
      }
      return showContent
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'CardManagement',
        'field_list': ['pathImg', 'fio', 'post']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Информация о руководстве и должностях компании Фарм-Инновации' },
      { name: 'title',
        content: 'Руководство' }
    ]
  }
}
</script>
