<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="career pr-16">
      <v-col v-if="showHeaderTextContent || isAuth" cols="12" lg="12" md="12" sm="12" class="career__content">
        <content-text
            class="career_head career_text"
            :title="$t('message.careerSubHead')"
            :content="$t('message.careerText1')"
            :hidden="!showHeaderTextContent"
            :id_title=201
            :id_sub-title=202
            :id_content=203
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
      </v-col>
    </v-row>
    <v-row class="career pr-16">
      <v-col v-if="showWeOffer || isAuth" cols="12" lg="5" md="5" sm="11" class="career__content">
        <content-text
            class="career_head career_text"
            :sub-title="$t('message.careerSubTitle1')"
            :hidden="showWeOffer"
            :id_title=204
            :id_sub-title=205
            :id_content=206
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
        <div v-if="loadedAddedContent && loadedDefaultContent">
          <div v-for="(content, index) in contentToShow" :key="index"
               class="contentTypesOfActivities career_text">
            <icon-text
               v-if="isAuth"
              :svg-path="content['svgPath']"
              :text="$t(content['text'])"
              :id_text="content['id_text']"
              :id_svg-path="content['id_svgPath']"
              :added="content['added']"
              :hidden="content['hidden']"
            />
          </div>
        </div>
        <v-btn v-if="isAuth" @click="add_content"> Добавить строчку с иконкой</v-btn>
        <content-text v-if="showJobQuestions || isAuth"
            class="career_head career_text"
            :sub-title="$t('message.careerSubTitle2')"
            :hidden="!showJobQuestions"
            :id_title=207
            :id_sub-title=208
            :id_content=209
            :content="'<p>'+$t('message.careerText5') +' '+ phoneHref + '</p>' +
            '<p>'+$t('message.careerText6') +' '+ HHHref + '</p>' +
            $t('message.careerText7')"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
      </v-col>
      <v-col cols="12" lg="4" md="5" sm="11" offset-lg="1" offset-md="1" offset-sm="0" class="career__content">
        <work-form
            :fio-label="$t('message.careerWorkForm1')"
            :vacantion-label="$t('message.careerWorkForm2')"
            :city-label="$t('message.careerWorkForm3')"
            :file-label="$t('message.careerWorkForm4')"
            email-label="E-mail"
            :text-label="$t('message.careerWorkForm6')"
            fio-svg="perm_identity"
            vacantion-svg="work_outline"
            city-svg="location_city"
            file-svg="attach_file"
            email-svg="email"
            text-svg="comment"
            :id_fio-label="225"
            :id_vacantion-label="226"
            :id_city-label="227"
            :id_file-label="228"
            :id_email-label="229"
            :id_text-label="230"
            :id_fio-svg="231"
            :id_vacantion-svg="232"
            :id_city-svg="233"
            :id_file-svg="234"
            :id_email-svg="235"
            :id_text-svg="236"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import WorkForm from '../components/ui/WorkForm'
import { mdiFinance, mdiCash, mdiBrain } from '@mdi/js'
import ContentText from '@/components/ui/ContentText.vue'
import IconText from '@/components/ui/IconText.vue'
import TextHref from '@/components/ui/TextHref.vue'
export default {
  components: { ContentText, WorkForm, IconText, TextHref },
  data () {
    let addedContent = []
    let defaultContent = [{
      svgPath: mdiFinance,
      text: 'message.careerText2',
      id_text: 213,
      id_svgPath: 214
    },
    {
      svgPath: mdiCash,
      text: 'message.careerText3',
      id_text: 215,
      id_svgPath: 216
    },
    {
      svgPath: mdiBrain,
      text: 'message.careerText4',
      id_text: 217,
      id_svgPath: 218
    }]
    let idList1 = [[201, 202, 203]]
    let defaultContentIdList = defaultContent.map(cont => [cont['id_text'], cont['id_svgPath']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultContent = true
      })
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList1 })
      .then(() => {
        let hidden_content = this.$store.getters['contentChanges/hidden_content']
        this.showHeaderTextContent = !hidden_content[0]
      })
    let idList2 = [[204, 205, 206]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList2 })
      .then(() => {
        let hidden_content = this.$store.getters['contentChanges/hidden_content']
        this.showWeOffer = !hidden_content[0]
      })
    let idList3 = [[207, 208, 209]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList3 })
      .then(() => {
        let hidden_content = this.$store.getters['contentChanges/hidden_content']
        this.showJobQuestions = !hidden_content[0]
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', { 'type': 'IconTextCareer' })
      .then(() => {
        addedContent = this.$store.getters['contentChanges/added_content_by_type']('IconTextCareer')
        this.addedContent = addedContent
        this.loadedAddedContent = true
      })
    return {
      defaultContent: defaultContent,
      addedContent: [],
      HHHref: '<a href="https://smolensk.hh.ru/employer" target="_blank" class="colloredLink"> HeadHunter </a>',
      phoneHref: '<a class="colloredLink" href="tel:' + this.$t('message.contactPhoneNum') + '">' + this.$t('message.contactPhoneNum') + '</a>',
      showHeaderTextContent: false,
      showWeOffer: false,
      showJobQuestions: false,
      showFormText: false,
      loadedAddedContent: false,
      loadedDefaultContent: false
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_svgPath'] = this.get_field_id(content, 'svgPath')
        newAddedContent['id_text'] = this.get_field_id(content, 'text')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'IconTextCareer',
        'field_list': ['text', 'svgPath']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Работа в фрамацевтической компании Фарм-Инновации, преимущества работы с нами и ссылки для устройства на работу'
      },
      { name: 'title',
        content: 'Карьера'
      }
    ]
  }
}
</script>
