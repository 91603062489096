<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <about-company-submenu/>
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="12" md="12" sm="12" class="mt-0 pr-6 pr-sm-6 pr-md-0 pr-lg-0 partnership__content" v-for="value in news" :key="value.id">
        <div v-if="lang=='ru'" >
          <h1 :id="value.anchor" :refs="value.anchor" class="contentHeading" v-html="value.title"/>
          <h5 class="newsData">
            {{ getDate(value.created_view)}}
          </h5>
          <div class="pl-2 contentAnticorruption" v-html="value.text"></div>
          <v-img
              v-if="value.img"
              width="300"
              height="300"
              :src="value.img"
          ></v-img>
        </div>
        <div v-else>
          <h1 :id="value.anchor" class="contentHeading" v-html="value.en_title" />
          <h5 class="newsData">
            {{ getDate(value.created_view) }}
          </h5>
          <div class="pl-2 contentAnticorruption" v-html="value.en_text"></div>
          <v-img
              v-if="value.img"
              width="300"
              height="300"
              :src="value.img"
          ></v-img>
        </div>
      </v-col>
      <v-col class="partnership__content">
        <v-pagination
        v-model="page_number"
        total-visible="6"
        :length = "this.page_count"
        color="#007070"
        @input = "update_news()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
export default {
  components: { AboutCompanySubmenu },
  data () {
    let page_number = this.$route.params['page_number']
    let news_number = this.$route.params['news_number']
    let news_on_page = this.$store.getters['news/news_on_page']
    if (typeof page_number == 'string')
      page_number = parseInt(page_number)
    if (typeof page_number == 'number' && 
    typeof news_on_page == 'number' &&
    !isNaN(page_number) && !isNaN(news_on_page))
      this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': news_on_page, 'offset': (page_number - 1) * news_on_page })
        .then(() => {
          this.scroll_to_news()
        })
    this.$store.dispatch('news/GET_COUNT')
    return {
      news_on_page: news_on_page,
      page_number: page_number,
      news_number: news_number
    }
  },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    news () {
      var _news = this.$store.state['news']['news']
      return _news.map(function (value, index) {
        var newValue = value
        newValue['anchor'] = index
        return newValue
      })
    },
    page_count() {
      return this.$store.getters['news/page_count']
    }
  },
  mounted () {
    this.scroll_to_news()
  },
  methods: {
    getDate (value) {
      var date = new Date(value)
      var data = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return data
    },
    update_news() {
      this.$router.push({ name: 'news_page_with_page',
                          params: {page_number: this.page_number}})
      this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': this.news_on_page, 'offset': (this.page_number - 1) * this.news_on_page })
    },
    scroll_to_news() {
      if (this.news_number) {
        let news = window.document.getElementById(this.news_number)
        if (news)
          this.$nextTick(() => news.scrollIntoView())
      }
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Новости компании Фарм-Инновации' },
      { name: 'title',
        content: 'Новости' }
    ]
  }
}
</script>
