<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="career pr-16">
      <v-col cols="12" lg="4" md="4" sm="12" class="career__content">
        <div v-if="loadedAddedTextContent && loadedDefaultTextContent">
          <content-text v-for="(content, index) in textContentToShow" :key="index"
                        :title="content['title']"
                        :sub-title="content['subTitle']"
                        :content="content['content']"
                        :id_title="content['id_title']"
                        :id_sub-title="content['id_subTitle']"
                        :id_content="content['id_content']"
                        :hidden="content['hidden']"
                        title-class="contentHeading contentSubTitle__career"
                        sub-title-class="contentSubTitle contentSubTitle__career"
                        text-class="contentText contentSubTitle__career"
          />
        </div>
        <v-btn v-if="isAuth" @click="add_text_content"> Добавить текстовый блок </v-btn>
        <v-row justify="center">
          <v-dialog v-model="dialog"
                    max-width="568"
          >
            <v-card class="pa-8">
              <v-card-title class="headline">
                {{ $t('message.feedBackForm') }}
              </v-card-title>
              <v-card-text>
                  <feedback/>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col cols="12" lg="8" md="8" sm="12" class="career__content">
        <content-frame
              frame-src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad2f3f2da121b706c2dc893813751a1801ff7539afcc9f3997eb07944bdbeb37c&amp;source=constructor"
              div-class="partnershipAdvantage pa-0"
              :id_frame-src = "350"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import Feedback from '../components/ui/FeedbackForm'
import ContentText from '@/components/ui/ContentText.vue'
import ContentFrame from '@/components/ui/ContentFrame.vue'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { ContentFrame, Feedback, ContentText },
  data () {
    let addedTextContent = []
    let defaultTextContent = [
      {
        title: this.$t('message.submenu4'),
        subTitle: this.$t('message.contactSubTitle1'),
        content: {
          'value_rus': '<p>' + this.$t('message.contactText2', 'ru') + '</p>' +
              '<p>' + this.$t('message.contactPhone', 'ru') + ': ' +
              '<a class="colloredLink" href="tel:' + this.$t('message.contactPhoneNum', 'ru') + '">' + this.$t('message.contactPhoneNum', 'ru') + '</a>' + '</p>',
          'value_eng': '<p>' + this.$t('message.contactText2', 'en-US') + '</p>' +
              '<p>' + this.$t('message.contactPhone', 'en-US') + ': ' +
              '<a class="colloredLink" href="tel:' + this.$t('message.contactPhoneNum', 'en-US') + '">' + this.$t('message.contactPhoneNum', 'en-US') + '</a>' + '</p>'
        },
        id_title: 339,
        id_subTitle: 340,
        id_content: 341
      },
      {
        subTitle: this.$t('message.contactSubTitle2'),
        content: {
          'value_rus': '<p>' + this.$t('message.contactText2', 'ru') + '</p>' +
              '<p>' + this.$t('message.contactText3', 'ru') + '</p>' +
              '<p>' + this.$t('message.contactText4', 'ru') + ': ' +
              '<a href="mailto:farminn@farminn.ru" target="_blank" class="colloredLink"> farminn@farminn.ru </a>',
          'value_eng': '<p>' + this.$t('message.contactText2', 'en-US') + '</p>' +
              '<p>' + this.$t('message.contactText3', 'en-US') + '</p>' +
              '<p>' + this.$t('message.contactText4', 'en-US') + ': ' +
              '<a href="mailto:farminn@farminn.ru" target="_blank" class="colloredLink"> farminn@farminn.ru </a>'
        },
        id_title: 342,
        id_subTitle: 343,
        id_content: 344
      }
    ]

    let defaultTextContentIdList = defaultTextContent.map(cont => [cont['id_title'], cont['id_subTitle'], cont['id_content']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultTextContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultTextContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultTextContent = true
      })

    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentTextContacts'
    }).then(() => {
      addedTextContent = this.$store.getters['contentChanges/added_content_by_type']('ContentTextContacts')
      this.addedTextContent = addedTextContent
      this.loadedAddedTextContent = true
    })

    return {
      defaultTextContent: defaultTextContent,
      addedTextContent: addedTextContent,
      loadedAddedTextContent: false,
      loadedDefaultTextContent: false,
      dialog: false,
      mailHref: '<a href="mailto:farminn@farminn.ru" target="_blank" class="colloredLink"> farminn@farminn.ru </a>',
      phoneHref: '<a class="colloredLink" href="tel:' + this.$t('message.contactPhoneNum') + '">' + this.$t('message.contactPhoneNum') + '</a>',
      svgAllNewsBtnPath: mdiChevronRight
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    textContentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultTextContent
      } else {
        defaultContentToShow = this.defaultTextContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedTextContent) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['id_subTitle'] = this.get_field_id(content, 'subTitle')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    add_text_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'ContentTextContacts',
        'field_list': ['title', 'subTitle', 'content']
      }).then(() => {
        let lastAddedContent = this.$store.getters['contentChanges/last_added_content']
        this.addedTextContent.push(lastAddedContent)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    },
    get_i18n_data (key, lang) {
      return this.$i18n[lang]['message'][key]
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Ссылка, номер телефона и форма для связи с компанией Фарм инновация' },
      { name: 'title',
        content: 'Контакты' }
    ]
  }
}
</script>
