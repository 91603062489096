<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <about-company-submenu :toggle_none="this.toggle_exclusive" />
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="12" md="12" sm="12" class="partnership__content pr-4">
        <content-text
            v-if="showContentText || isAuth"
            :title="$t('message.aboutUsSubmenu2')"
            :id_title="299"
            :id_sub-title="300"
            :id_content="301"
            :hidden="!showContentText"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
        <div v-if="loadedDefaultContent && loadedAddedContent">
          <block-documents v-for="(content, index) in contentToShow" :key="index"
            :title="$t(content['title'])"
            :id_title="content['id_title']"
            :type="content['type']"
            :added="content['added']"
            :hidden="content['hidden']"
          />
        </div>
        <v-btn v-if="isAuth" @click="add_content">Добавить категорию</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import BlockDocuments from '../components/ui/BlockDocuments'
import ContentText from '@/components/ui/ContentText.vue'
export default {
  components: { ContentText, BlockDocuments, AboutCompanySubmenu },
  data () {
    let addedContent = []
    let defaultContent =
      [{
        title: 'message.aboutUsSubTitle1',
        id_title: 96,
        type: 'founding_documents'
      },
      {
        title: 'message.aboutUsSubTitle3',
        id_title: 98,
        type: 'law_document'
      },
      {
        title: 'message.aboutUsSubTitle2',
        id_title: 97,
        type: 'inner_documents'
      }
      ]

    let contentTextIdList = [[299, 300, 301]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': contentTextIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.showContentText = true
      })

    let defaultContentIdList = defaultContent.map(cont => [cont['id_title']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultContent = true
      })

    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'BlockDocuments'
    }).then(() => {
      addedContent = this.$store.getters['contentChanges/added_content_by_type']('BlockDocuments')
      this.addedContent = addedContent
      this.loadedAddedContent = true
    })

    return {
      showContentText: false,
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      toggle_exclusive: 1
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'BlockDocuments',
        'field_list': ['title']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Документация компании Фарм-Инновации, учредительные документы, юридические документы и внутренние документы сообщества' },
        { name: 'title',
        content: 'Документы' }
    ]
  }
}
</script>
