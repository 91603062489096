<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <about-company-submenu :toggle_none="this.toggle_exclusive" />
    </v-row>
    <v-row class="partnership">
      <v-col v-if="showDefaultTextContent" cols="12" lg="6" md="6" sm="12" class="mt-4 partnership__content">
        <content-text
            v-if="showDefaultTextContent || isAuth"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
            :id_title="414"
            :id_sub-title="415"
            :id_content="416"
            :title="$t('message.aboutUsSubmenu5')"
            :hidden="!showDefaultTextContent"
        />
      </v-col>
    </v-row>
    <v-row class="partnership">
      <v-col v-for="value in news" lg="4" md="4" sm="12" class="mt-4 partnership__content pr-10 pl-2">
<!--        {{ value.anchor }}-->
        <card-news v-if="lang=='ru'"
                   :header="value.title"
                   :data="getDate(value.created_view)"
                   :text="value.anons"
                   :anchor="value.anchor"
        />
        <card-news v-else
                   :header="value.en_title"
                   :data="getDate(value.created_view)"
                   :text="value.en_anons"
                   :anchor="value.anchor"
        />
      </v-col>
      <v-col cols="12" class="partnership__content">
        <v-pagination
        v-model="page_number"
        total-visible="6"
        :length = "this.page_count"
        color="#007070"
        @input = "update_news()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import CardNews from '../components/ui/CardNews'
import ContentText from "@/components/ui/ContentText.vue";
export default {
  components: {ContentText, AboutCompanySubmenu, CardNews },
  data () {
    let DefaultTextContentIdList = [[414, 415, 416]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': DefaultTextContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showDefaultTextContent = !hiddenContent[0]
      })
    let offset = this.$store.getters['news/offset']
    let news_on_page = this.$store.getters['news/news_on_page']
    this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': news_on_page, 'offset': offset })
    this.$store.dispatch('news/GET_COUNT')
    return {
      news_on_page: news_on_page,
      page_number : 1,
      showDefaultTextContent: false,
      toggle_exclusive: 4
    }
  },
  methods: {
    getDate (value) {
      var date = new Date(value)
      var data = ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear()
      return data
    },
    update_news() {
      this.$store.dispatch('news/GET_NEWS', { 'filters': [], 'limit': this.news_on_page, 'offset': (this.page_number - 1) * this.news_on_page })
    }
  },
  computed: {
    lang () { return this.$store.state['lang']['locale'] },
    news () {
      var _news = this.$store.state['news']['news']
      return _news.map(function (value, index) {
        var new_value = value
        new_value['anchor'] = index
        return new_value
      })
    },
    news_count() {
      return this.$store.getters['news/count']
    },
    page_count() {
      return this.$store.getters['news/page_count']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Новости компании Фарм-Инновации, новые достижения и события компании' },
      { name: 'title',
        content: 'Все новости' }
    ]
  }
}
</script>
