<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
        <partnership-submenu :toggle_none="this.toggle_exclusive"/>
    </v-row>
    <v-row class="partnership">
      <v-col v-if="show1 || isAuth" cols="12" lg="5" md="5" sm="11" class="partnership__content">
        <content-text
            :title="$t('message.partnershipSubHead3')"
            :content ="'<p>'+ gorHref + ' - ' + $t('message.partnershipText10') +
            span1 + $t('message.partnershipText11') + '</p>' +
            '<p>'+ $t('message.partnershipText12')+ '</p>' +
            '<p>'+ span2+' ' + $t('message.partnershipText14')+'</p>'"
            :id_title="330"
            :id_sub-title="331"
            :id_content="332"
            :hidden="!show1"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
      </v-col>
      <v-col
          :v-if="show2 || isAuth"
          cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
        <content-text
          :sub-title="$t('message.partnershipSubHead4')"
          :content="'<ul><li><p>'+$t('message.partnershipText15')+'</p></li>' +
             '<li><p>' + $t('message.partnershipText16')+'</p></li>' +
             '<li><p>' + $t('message.partnershipText17')+'</p></li>' +
             '<li><p>' + $t('message.partnershipText18')+'</p></li></ul> ' +
             '<p>'+ $t('message.partnershipText19') + gorHref +
             $t('message.partnershipText20') + mailHref +
             $t('message.partnershipText21') + phoneHref + '</p>'"
          :id_title="333"
          :id_sub-title="334"
          :id_content="335"
          :hidden="!show2"
          title-class="contentHeading"
          sub-title-class="contentSubTitle partnershipSubTitle"
          text-class="contentText contentTextSuppliers contentAnticorruptio"
        />
      </v-col>
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="5" md="5" sm="11" class="partnership__content">
        <content-text
          :v-if="!show3 || isAuth"
          :sub-title="$t('message.partnershipSubHead5')"
          :content="$t('message.partnershipText22')"
          :id_title="336"
          :id_sub-title="337"
          :id_content="338"
          :hidden="!show3"
          title-class="contentHeading"
          sub-title-class="mt-0 contentSubTitle"
          text-class="contentText"
        />
        <offer-goods-form
              :name-label="$t('message.partnershipForSuppliersForm1')"
              :company-label="$t('message.partnershipForSuppliersForm2')"
              :site-label="$t('message.partnershipForSuppliersForm3')"
              :region-label="$t('message.partnershipForSuppliersForm4')"
              :contact_name-label="$t('message.partnershipForSuppliersForm5')"
              email-label="E-mail"
              :file-label="$t('message.partnershipForSuppliersForm8')"
              :text-label="$t('message.partnershipForSuppliersForm9')"
              name-svg="star_border"
              company-svg="account_balance"
              site-svg="http"
              region-svg="my_location"
              contact_name-svg="perm_identity"
              email-svg="email"
              file-svg="attach_file"
              text-svg="comment"
              :id_name-label="243"
              :id_company-label="244"
              :id_site-label="245"
              :id_region-label="246"
              :id_contact_name-label="247"
              :id_email-label="248"
              :id_file-label="249"
              :id_text-label="250"
              :id_name-svg="251"
              :id_company-svg="252"
              :id_site-svg="253"
              :id_region-svg="254"
              :id_contact_name-svg="255"
              :id_email-svg="256"
              :id_file-svg="257"
              :id_text-svg="257"
              class="ml-0 d-block d-sm-block d-md-none d-lg-none"/>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
        <content-text
          :v-if="!show4 || isAuth"
          :sub-title="$t('message.partnershipSubHead6')"
          :content="'<p>' + $t('message.partnershipText23') + phoneHref + '</p>'"
          title-class="contentHeading"
          sub-title-class="contentSubTitle ml-lg-0 ml-md-0 mt-6"
          text-class="contentText mt-4 ml-0"
          :hidden="!show4"
          :id_content="391"
          :id_sub-title="392"
          :id_title="393"
        />
          <report-form
            :name-label="$t('message.partnershipForSuppliersForm2')"
            :contact_name-label="$t('message.partnershipForSuppliersForm5')"
            email-label="E-mail"
            :text-label="$t('message.partnershipForSuppliersForm11')"
            name-svg="account_balance"
            contact_name-svg="perm_identity"
            email-svg="email"
            text-svg="comment"
            :id_name-label="258"
            :id_contact_name-label="259"
            :id_email-label="260"
            :id_text-label="261"
            :id_name-svg="262"
            :id_text-svg="263"
            :id_email-svg="264"
            :id_contact_name-svg="265"
            class="ml-0 d-block d-sm-block d-md-none d-lg-none"/>
      </v-col>
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="5" md="5" sm="11" class="partnership__content">
        <offer-goods-form
            :name-label="$t('message.partnershipForSuppliersForm1')"
              :company-label="$t('message.partnershipForSuppliersForm2')"
              :site-label="$t('message.partnershipForSuppliersForm3')"
              :region-label="$t('message.partnershipForSuppliersForm4')"
              :contact_name-label="$t('message.partnershipForSuppliersForm5')"
              email-label="E-mail"
              :file-label="$t('message.partnershipForSuppliersForm8')"
              :text-label="$t('message.partnershipForSuppliersForm9')"
              name-svg="star_border"
              company-svg="account_balance"
              site-svg="http"
              region-svg="my_location"
              contact_name-svg="perm_identity"
              email-svg="email"
              file-svg="attach_file"
              text-svg="comment"
              :id_name-label="243"
              :id_company-label="244"
              :id_site-label="245"
              :id_region-label="246"
              :id_contact_name-label="247"
              :id_email-label="248"
              :id_file-label="249"
              :id_text-label="250"
              :id_name-svg="251"
              :id_company-svg="252"
              :id_site-svg="253"
              :id_region-svg="254"
              :id_contact_name-svg="255"
              :id_email-svg="256"
              :id_file-svg="257"
              :id_text-svg="257"
            class="mt-0 d-none d-sm-none d-md-block d-lg-block"/>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
        <report-form
            :name-label="$t('message.partnershipForSuppliersForm2')"
            :contact_name-label="$t('message.partnershipForSuppliersForm5')"
            email-label="E-mail"
            :text-label="$t('message.partnershipForSuppliersForm11')"
            name-svg="account_balance"
            contact_name-svg="perm_identity"
            email-svg="email"
            text-svg="comment"
            :id_name-label="258"
            :id_contact_name-label="259"
            :id_email-label="260"
            :id_text-label="261"
            :id_name-svg="262"
            :id_text-svg="263"
            :id_email-svg="264"
            :id_contact_name-svg="265"
            class="mt-0 d-none d-sm-none d-md-block d-lg-block"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu.vue'
import ContentText from '../components/ui/ContentText'
import ContentNumeric from '../components/ui/ContentNumeric'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import OfferGoodsForm from '../components/ui/OfferGoodsForm'
import ReportForm from '../components/ui/ReportForm'
import TextHref from "@/components/ui/TextHref.vue";
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { PartnershipSubmenu, ContentText, ContentNumeric, AboutCompanySubmenu, OfferGoodsForm, ReportForm },
  data () {
    let IdList1 = [[330, 331, 332]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList1 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show1 = !hiddenContent[0]
      })
    let IdList2 = [[333, 334, 335]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList2 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show2 = !hiddenContent[0]
      })
    let IdList3 = [[336, 337, 338]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList3 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show3 = !hiddenContent[0]
      })

    let IdList4 = [[391, 392, 393]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList4 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show4 = !hiddenContent[0]
      })
    return {
      gorHref: '<a href="https://городскаяаптека.рф" target="_blank" class="colloredLink">городскаяаптека.рф</a>',
      span1: '<span class="contentNote">* </span>',
      span2: '<span class="contentNote">*' + this.$t("message.partnershipText13") +' </span>',
      mailHref: '<a href="mailto:farminn@farminn.ru" target="_blank" class="colloredLink"> farminn@farminn.ru </a>',
      phoneHref: '<a class="colloredLink" href="tel:' + this.$t("message.contactPhoneNum") +'">' + this.$t("message.contactPhoneNum") + '</a>',
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_exclusive: 1,
      show1: false,
      show2: false,
      show3: false,
      show4: false
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Информация для поставщиков о компании Фарм-Инновации, формы предложения товара, запрос на получение информации по продажам' },
      { name: 'title',
        content: 'Поставщикам' }
    ]
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    }
  }
}
</script>
