<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <about-company-submenu />
    </v-row>
    <v-row class="partnership">
      <v-col v-if="loadedDefaultTextContent && loadedAddedTextContent" cols="12" lg="5" md="5" sm="11" class="partnership__content">
        <content-text
            v-for="(content, index) in textContentToShow" :key="index"
                :title="$t(content['title'])"
                :sub-title="$t(content['subTitle'])"
                :content="content['content']"
                :id_title="content['id_title']"
                :id_sub-title="content['id_subTitle']"
                :id_content="content['id_content']"
                :hidden="content['hidden']"
                :added="content['added']"
                title-class="contentHeading"
                sub-title-class="contentSubTitle"
                text-class="contentText"
        />
        <v-btn v-if="isAuth" @click="add_text_content"> Добавить текстовый блок </v-btn>
      </v-col>
        <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
          <div class="py-6">
            <content-text
                v-if="showNumericTextContent || isAuth"
                :sub-title="$t('message.contentNumericHead')"
                :id_title=198
                :id_sub-title=199
                :id_content=200
                title-class="contentHeading"
                sub-title-class="contentSubTitle"
                text-class="contentText"
                :hidden="!showNumericTextContent"
            />
          </div>
          <v-row class="no-indentation ml-0" v-if="loadedAddedContent && loadedDefaultContent">
            <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
                <content-numeric
                                 v-for="(content, index) in firstCol" :key="index"
                                 :num="$t(content['num'])"
                                 :unit="$t(content['unit'])"
                                 :symbol="content['symbol']"
                                 :content="$t(content['content'])"
                                 :id_num="content['id_num']"
                                 :id_unit="content['id_unit']"
                                 :id_symbol="content['id_symbol']"
                                 :id_content="content['id_content']"
                                 :hidden="content['hidden']"
                                 :added="content['added']"
                />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
              <content-numeric
                                 v-for="(content, index) in secondCol" :key="index"
                                 :num="$t(content['num'])"
                                 :unit="$t(content['unit'])"
                                 :symbol="content['symbol']"
                                 :content="$t(content['content'])"
                                 :id_num="content['id_num']"
                                 :id_unit="content['id_unit']"
                                 :id_symbol="content['id_symbol']"
                                 :id_content="content['id_content']"
                                 :hidden="content['hidden']"
                                 :added="content['added']"
                 />
            </v-col>
            <v-btn v-if="isAuth" @click="add_content"> Добавить контент цифр</v-btn>
          </v-row>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import ContentText from '../components/ui/ContentText'
import ContentNumeric from '../components/ui/ContentNumeric'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { ContentText, ContentNumeric, AboutCompanySubmenu },
  props: ['submenu'],
  data () {
    let addedContent = []
    let addedTextContent = []
    let defaultTextContent = [
      {
        title: 'message.submenu1',
        content: {
          'value_rus': '<p>' + this.$t('message.aboutUsText1', 'ru') + '</p>' +
            '<p>' + this.$t('message.aboutUsText2', 'ru') + '</p>' +
            '<p>' + this.$t('message.aboutUsText3', 'ru') + '</p>',
          'value_eng': '<p>' + this.$t('message.aboutUsText1', 'en-US') + '</p>' +
            '<p>' + this.$t('message.aboutUsText2', 'en-US') + '</p>' +
            '<p>' + this.$t('message.aboutUsText3', 'en-US') + '</p>'
        },
        id_title: 195,
        id_subTitle: 196,
        id_content: 197
      }
    ]
    let defaultContent = [
      {
        num: 'message.contentNum3',
        unit: 'message.contentUnit3',
        symbol: '+',
        content: 'message.contentNumeric3',
        id_num: 136,
        id_unit: 137,
        id_symbol: 397,
        id_content: 138
      },
      {
        num: 'message.contentNum4',
        symbol: '+',
        content: 'message.contentNumeric4',
        id_num: 139,
        id_unit: 140,
        id_symbol: 141,
        id_content: 142
      },
      {
        num: 'message.contentNum5',
        content: 'message.contentNumeric5',
        id_num: 143,
        id_unit: 144,
        id_symbol: 145,
        id_content: 146
      },
      {
        num: 'message.contentNum8',
        symbol: '+',
        content: 'message.contentNumeric8',
        id_num: 147,
        id_unit: 148,
        id_symbol: 149,
        id_content: 150
      },
      {
        num: 'message.contentNum6',
        content: 'message.contentNumeric6',
        id_num: 151,
        id_unit: 152,
        id_symbol: 153,
        id_content: 154
      },
      {
        num: 'message.contentNum9',
        symbol: '+',
        content: 'message.contentNumeric9',
        id_num: 155,
        id_unit: 156,
        id_symbol: 157,
        id_content: 158
      },
      {
        num: 'message.contentNum7',
        unit: 'message.contentUnit2',
        symbol: '+',
        content: 'message.contentNumeric7',
        id_num: 159,
        id_unit: 160,
        id_symbol: 161,
        id_content: 162
      },
      {
        num: 'message.contentNum1',
        symbol: '+',
        content: 'message.contentNumeric1',
        id_num: 191,
        id_unit: 192,
        id_symbol: 193,
        id_content: 194
      }
    ]

    let defaultContentIdList = defaultContent.map(cont => [cont['id_num'], cont['id_symbol'], cont['id_content'], cont['id_unit']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultContent = true
      })

    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentNumericAboutCompany'
    }).then(() => {
      addedContent = this.$store.getters['contentChanges/added_content_by_type']('ContentNumericAboutCompany')
      this.addedContent = addedContent
      this.loadedAddedContent = true
    })

    let defaultTextContentIdList = defaultTextContent.map(cont => [cont['id_title'], cont['id_subTitle'], cont['id_content']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultTextContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultTextContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultTextContent = true
      })

    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentTextAboutCompany'
    }).then(() => {
      addedTextContent = this.$store.getters['contentChanges/added_content_by_type']('ContentTextAboutCompany')
      this.addedTextContent = addedTextContent
      this.loadedAddedTextContent = true
    })

    let numbersContentTextIdList = [[198, 199, 200]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': numbersContentTextIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showNumericTextContent = !hiddenContent[0]
      })
    return {
      loadedAddedTextContent: false,
      loadedDefaultTextContent: false,
      loadedAddedContent: false,
      loadedDefaultContent: false,
      showNumericTextContent: false,
      defaultTextContent: defaultTextContent,
      addedTextContent: addedTextContent,
      defaultContent: defaultContent,
      addedContent: addedContent
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_num'] = this.get_field_id(content, 'num')
        newAddedContent['id_unit'] = this.get_field_id(content, 'unit')
        newAddedContent['id_symbol'] = this.get_field_id(content, 'symbol')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    textContentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultTextContent
      } else {
        defaultContentToShow = this.defaultTextContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedTextContent) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['id_subTitle'] = this.get_field_id(content, 'subTitle')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    firstCol () {
      return this.contentToShow.filter((item, index) => index % 2 == 1)
    },
    secondCol () {
      return this.contentToShow.filter((item, index) => index % 2 == 0)
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'ContentNumericAboutCompany',
        'field_list': ['num', 'unit', 'symbol', 'content']
      }).then(() => {
        let lastAddedContent = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(lastAddedContent)
      })
    },
    add_text_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'ContentTextAboutCompany',
        'field_list': ['title', 'subTitle', 'content']
      }).then(() => {
        let lastAddedContent = this.$store.getters['contentChanges/last_added_content']
        this.addedTextContent.push(lastAddedContent)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    },
    get_i18n_data (key, lang) {
      return this.$i18n[lang]['message'][key]
    }
  },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Информация о компании Фарм-Инновации, основные виды деятельности, приориететы развития и основные достижения'
      },
      {
        name: 'title',
        content: 'О компании '
      }
    ]
  }
}
</script>
