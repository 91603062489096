<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <partnership-submenu :toggle_none="this.toggle_exclusive"/>
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="5" md="5" sm="11" class="partnership__content">
        <content-text
            v-if="show1 || isAuth"
            :title="$t('message.partnershipSubmenu1')"
                      :content="'<p>'+$t('message.partnershipText2')+'</p>' +
                      '<p>'+$t('message.partnershipText3')+'</p>' +
                      '<p>'+$t('message.partnershipText4')+'</p>' +
                      '<p>'+$t('message.partnershipText5')+'</p>' +
                      '<p>'+$t('message.partnershipText6')+'</p>' +
                      '<p>'+$t('message.partnershipText7')+'</p>' +
                      '<p>'+$t('message.partnershipText8')+'</p>' +
                      '<p>'+$t('message.partnershipText9')+'</p>'"
                      :id_content="318"
                      :id_title="319"
                      :id_sub-title="320"
                      :hidden="!show1"
                      title-class="contentHeading"
                      sub-title-class="contentSubTitle"
                      text-class="contentText"
        />
        <content-text
            v-if="show2 || isAuth"
            :sub-title="$t('message.partnershipLogisticsSubHead1')"
                      :content="'<p>'+$t('message.aboutUsText3')+'</p>'+
                                '<p>'+$t('message.aboutUsText4')+'</p>'+
                                '<p>'+$t('message.aboutUsText5')+'</p>'"
                      :id_content="321"
                      :id_sub-title="322"
                      :id_title="323"
                      :hidden="!show2"
                      title-class="contentHeading"
                      sub-title-class="contentSubTitle"
                      text-class="contentText"
        />
      </v-col>
        <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
          <v-row class="no-indentation ml-0" v-if="loadedAddedContent && loadedDefaultContent">
            <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
                <content-text
                    v-if="show3 || isAuth"
                    :sub-title="$t('message.partnershipLogisticsNumericHead')"
                    :id_title="324"
                    :id_sub-title="325"
                    :id_content="326"
                    :hidden="!show3"
                    title-class="contentHeading"
                    sub-title-class="contentSubTitle partnershipSubHeading"
                    text-class="contentText"
                />
                <content-numeric
                                 v-for="(content, index) in firstCol" :key="index"
                                 :num="$t(content['num'])"
                                 :unit="$t(content['unit'])"
                                 :symbol="content['symbol']"
                                 :content="$t(content['content'])"
                                 :id_num="content['id_num']"
                                 :id_unit="content['id_unit']"
                                 :id_symbol="content['id_symbol']"
                                 :id_content="content['id_content']"
                                 :hidden="content['hidden']"
                                 :added="content['added']"
                />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
              <content-text
                  v-if="show4 || isAuth"
                    :sub-title="$t('message.partnershipWarehouseNumericHead')"
                    :id_title="327"
                    :id_sub-title="328"
                    :id_content="329"
                    :hidden="!show4"
                    title-class="contentHeading"
                    sub-title-class="contentSubTitle partnershipSubHeading"
                    text-class="contentText"
              />
              <content-numeric
                                 v-for="(content, index) in secondCol" :key="index"
                                 :num="$t(content['num'])"
                                 :unit="$t(content['unit'])"
                                 :symbol="content['symbol']"
                                 :content="$t(content['content'])"
                                 :id_num="content['id_num']"
                                 :id_unit="content['id_unit']"
                                 :id_symbol="content['id_symbol']"
                                 :id_content="content['id_content']"
                                 :hidden="content['hidden']"
                                 :added="content['added']"
                 />
            </v-col>
            <v-btn v-if="isAuth" @click="add_content"> Добавить контент цифр</v-btn>
          </v-row>
        </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu'
import ContentNumeric from '@/components/ui/ContentNumeric.vue'
import ContentText from '@/components/ui/ContentText.vue'
import IconText from '@/components/ui/IconText.vue'
export default {
  components: { IconText, ContentText, ContentNumeric, PartnershipSubmenu },
  data () {
    let addedContent = []
    let defaultContent = [
      {
        num: '1000 +',
        content: 'message.partnershipNumeric1',
        id_num: 163,
        id_unit: 164,
        id_symbol: 165,
        id_content: 166
      },
      {
        subtitle: 'message.aboutUsSubHeading',
        num: '3 000',
        unit: 'message.contentUnit2',
        content: 'message.contentNumeric7',
        id_num: 167,
        id_unit: 168,
        id_symbol: 169,
        id_content: 170
      },
      {
        num: '100',
        content: 'message.partnershipNumeric2',
        id_num: 171,
        id_unit: 172,
        id_symbol: 173,
        id_content: 174
      },
      {
        num: '5 100',
        unit: 'message.contentUnit4',
        content: 'message.partnershipNumeric7',
        id_num: 175,
        id_unit: 176,
        id_symbol: 177,
        id_content: 178
      },
      {
        num: '48',
        unit: 'message.contentUnit6',
        content: 'message.partnershipNumeric4',
        id_num: 179,
        id_unit: 180,
        id_symbol: 181,
        id_content: 182
      },
      {
        num: '36 000',
        unit: 'message.contentUnit4',
        content: 'message.partnershipNumeric8',
        id_num: 183,
        id_unit: 184,
        id_symbol: 185,
        id_content: 186
      },
      {
        num: '36 000',
        unit: 'message.contentUnit4',
        content: 'message.partnershipNumeric9',
        id_num: 187,
        id_unit: 188,
        id_symbol: 189,
        id_content: 190
      }
    ]
    let id_list = defaultContent.map(cont => [cont['id_num'], cont['id_symbol'], cont['id_content'], cont['id_unit']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': id_list })
      .then(() => {
        let hidden_content = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hidden_content) {
          this.defaultContent[ind]['hidden'] = hidden_content[ind]
        }
        this.loadedDefaultContent = true
      })
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentNumericLogistics'
    }).then(() => {
      addedContent = this.$store.getters['contentChanges/added_content_by_type']('ContentNumericLogistics')
      this.addedContent = addedContent
      this.loadedAddedContent = true
    })
    let IdList1 = [[318, 319, 320]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList1 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show1 = !hiddenContent[0]
      })
    let IdList2 = [[321, 322, 323]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList2 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show2 = !hiddenContent[0]
      })
    let IdList3 = [[324, 325, 326]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList3 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show3 = !hiddenContent[0]
      })

    let IdList4 = [[327, 328, 329]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': IdList4 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show4 = !hiddenContent[0]
      })
    return {
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      toggle_exclusive: 0
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      } else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_num'] = this.get_field_id(content, 'num')
        newAddedContent['id_unit'] = this.get_field_id(content, 'unit')
        newAddedContent['id_symbol'] = this.get_field_id(content, 'symbol')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    firstCol () {
      let Elements = this.contentToShow.length % 2 == 0 ? this.contentToShow : this.contentToShow.slice(0, this.contentToShow.length - 1)
      return Elements.filter((item, index) => index % 2 == 0)
    },
    secondCol () {
      let Elements = this.contentToShow.filter((item, index) => index % 2 == 1)
      if (this.contentToShow.length % 2 == 1) {
        Elements.push(this.contentToShow[this.contentToShow.length - 1])
      }
      return Elements
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'ContentNumericLogistics',
        'field_list': ['num', 'unit', 'symbol', 'content']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Логистика и складская система компании Фарм-Инновации, ее складские мощности и эффективность' },
      { name: 'title',
        content: 'Логистика' }
    ]
  }
}
</script>
