<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <about-company-submenu :toggle_none="this.toggle_exclusive" />
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="6" md="6" sm="12" class="partnership__content">
        <ContentText
          v-if="showContentText || isAuth"
          :title="$t('message.aboutUsSubmenu31')"
          :id_title="266"
          :id_content="267"
          :id_sub-title="268"
          title-class="contentHeading"
          sub-title-class="contentSubTitle"
          text-class="contentText"
        />
        <tooltip-content
          v-if="showTooltip"
          :text="$t('message.aboutUsTypesOfActivitiesSveden')"
          :tolltip-text="$t('message.aboutUsTypesOfActivitiesEGRUL')"
          :svg-path="svgHelpPath"
          :id_text="275"
          :id_tolltip-text="276"
          :id_svg-path="277"
          class_div="contentTypesOfActivities contentTypesOfActivitiesComment"
        />
        <div v-if="loadedDefaultTextContent && loadedAddedTextContent">
          <content-text v-for="(content, index) in textContentToShow" :key="index"
            :sub-title="content['subTitle']"
            :content="content['content']"
            :id_title="content['id_title']"
            :id_sub-title="content['id_subTitle']"
            :id_content="content['id_content']"
            :added="content['added']"
            :hidden="content['hidden']"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
          />
        </div>
        <v-btn v-if="isAuth" @click="add_text_content"> Добавить текстовый блок </v-btn>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="10" offset-lg="1" offset-md="1" offset-sm="0" class="mx-auto mx-sm-0 marginLicense text-center">
          <license-slider></license-slider>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import ContentDocuments from '../components/ui/ContentDocuments'
import LicenseSlider from '../components/ui/LicenseSlider'
import ContentText from '@/components/ui/ContentText.vue'
import TooltipContent from '@/components/ui/TooltipContent.vue'
import { mdiHelpCircleOutline } from '@mdi/js'
export default {
  components: { TooltipContent, AboutCompanySubmenu, ContentDocuments, LicenseSlider, ContentText },
  data () {
    let addedTextContent = []
    let defaultTextContent = [
      {
        subTitle: {
          'value_rus': this.$t('message.aboutUsTypesOfActivitiesDop', 'ru'),
          'value_eng': this.$t('message.aboutUsTypesOfActivitiesDop', 'en-US')
        },
        content: {
          'value_rus': '<p>' + this.$t('message.aboutUsTypesOfActivitiesOsn', 'ru') + '</p>',
          'value_eng': '<p>' + this.$t('message.aboutUsTypesOfActivitiesOsn', 'en-US') + '</p>'
        },
        id_title: 269,
        id_subTitle: 270,
        id_content: 271
      },
      {
        subTitle: {
          'value_rus': this.$t('message.aboutUsTypesOfActivitiesDop', 'ru'),
          'value_eng': this.$t('message.aboutUsTypesOfActivitiesDop', 'en-US')
        },
        content: {
          'value_rus': '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop1', 'ru') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop2', 'ru') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop3', 'ru') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop4', 'ru') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop5', 'ru') + '</p>',
          'value_eng': '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop1', 'en-US') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop2', 'en-US') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop3', 'en-US') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop4', 'en-US') + '</p>' +
          '<p>' + this.$t('message.aboutUsTypesOfActivitiesDop5', 'en-US') + '</p>'
        },
        id_title: 272,
        id_subTitle: 273,
        id_content: 274
      }
    ]

    let defaultTextContentIdList = defaultTextContent.map(cont => [cont['id_title'], cont['id_subTitle'], cont['id_content']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultTextContentIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.defaultTextContent[ind]['hidden'] = hiddenContent[ind]
        }
        this.loadedDefaultTextContent = true
      })

    let tooltipIdList = [[275, 276, 277]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': tooltipIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showTooltip = !hiddenContent[0]
      })

    let contentTextIdList = [[266, 267, 268]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': contentTextIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showContentText = !hiddenContent[0]
      })

    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentTextTypeOfActivities'
    }).then(() => {
      addedTextContent = this.$store.getters['contentChanges/added_content_by_type']('ContentTextTypeOfActivities')
      this.addedTextContent = addedTextContent
      this.loadedAddedTextContent = true
    })
    return {
      toggle_exclusive: 2,
      svgHelpPath: mdiHelpCircleOutline,
      defaultTextContent: defaultTextContent,
      addedTextContent: addedTextContent,
      showTooltip: false,
      showContentText: false,
      loadedDefaultTextContent: false,
      loadedAddedTextContent: false
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    textContentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultTextContent
      } else {
        defaultContentToShow = this.defaultTextContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedTextContent) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['id_subTitle'] = this.get_field_id(content, 'subTitle')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    add_text_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'ContentTextTypeOfActivities',
        'field_list': ['title', 'subTitle', 'content']
      }).then(() => {
        let lastAddedContent = this.$store.getters['contentChanges/last_added_content']
        this.addedTextContent.push(lastAddedContent)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    },
    get_i18n_data (key, lang) {
      return this.$i18n[lang]['message'][key]
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Сведения о видах деятельности и лицензии компании Фарм-Инновации' },
      { name: 'title',
        content: 'Виды деятельности' }
    ]
  }
}
</script>
