<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <about-company-submenu :toggle_none="this.toggle_exclusive" />
    </v-row>
    <v-row class="partnership">
      <v-col v-if="loadedAddedTextContent1 && showTextContent1"
          cols="12" lg="6" md="6" sm="12" class="mt-8 pr-6 pr-sm-6 pr-md-0 pr-lg-0 partnership__content">
        <content-text v-for="(content, index) in textContent1ToShow" :key="index"
            :title="content['title']"
            :sub-title="content['subTitle']"
            :content="content['content']"
            :id_title="content['id_title']"
            :id_sub-title="content['id_subTitle']"
            :id_content="content['id_content']"
            :added="content['added']"
            :hidden="content['hidden']"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
      </v-col>
      <v-col v-if="loadedAddedTextContent2 && showTextContent2"
             cols="12" lg="6" md="6" sm="12" class="pr-6 pr-sm-6 pr-md-0 pr-lg-0 contentAnticorruptionMargin">
        <content-text v-for="(content, index) in textContent2ToShow" :key="index"
            :title="content['title']"
            :sub-title="content['subTitle']"
            :content="content['content']"
            :id_title="content['id_title']"
            :id_sub-title="content['id_subTitle']"
            :id_content="content['id_content']"
            :hidden="content['hidden']"
            :added="content['added']"
            div-class="contentAnticorruption"
            text-class="contentAnticorruption contentText"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import ContentText from "@/components/ui/ContentText.vue";
import TextHref from "@/components/ui/TextHref.vue";
export default {
  components: { ContentText, AboutCompanySubmenu, TextHref },
  data () {
    let farminnHerf = '<a href="mailto:farminn@farminn.ru" target="_blank" class="colloredLink">' + 'farminn@farminn.ru' + '</a>'
    let textContent1 = [{
      title: this.$t('message.aboutUsSubmenu4'),
      content: {
        'value_rus': '<p>' + this.$t('message.aboutUsAntiCorruption1', 'ru') + '</p>' +
            '<p>' + '<a href="/file/Codex_ru.pdf" target="_blank" class="colloredLink">' + this.$t('message.aboutUsDocument3', 'ru') + '</a>' + '</p>' +
            '<p>' + '<a href="/file/Anticorruption_ru.pdf" target="_blank" class="colloredLink">' + this.$t('message.aboutUsDocument2', 'ru') + '</a>' + '</p>' +
            '<p>' + this.$t('message.aboutUsAntiCorruption2', 'ru') + ' ' + farminnHerf + '</p>' +
            '<p><span class="contentNote">' + this.$t('message.aboutUsAntiCorruption3' , 'ru') + '</span></p>',
        'value_eng': '<p>' + this.$t('message.aboutUsAntiCorruption1', 'en-US') + '</p>' +
            '<p>' + '<a href="/file/Codex_ru.pdf" target="_blank" class="colloredLink">' + this.$t('message.aboutUsDocument3', 'en-US') + '</a>' + '</p>' +
            '<p>' + '<a href="/file/Codex_ru.pdf" target="_blank" class="colloredLink">' + this.$t('message.aboutUsDocument3', 'en-US') + '</a>' + '</p>' +
            '<p>' + this.$t('message.aboutUsAntiCorruption2', 'en-US') + ' ' + farminnHerf + '</p>' +
            '<p><span class="contentNote">' + this.$t('message.aboutUsAntiCorruption3' , 'en-US') + '</span></p>'
      },
      id_title: 278,
      id_subTitle: 279,
      id_content: 280
    }]
    let textContent2 = [{
      content: { 'value_rus': '<p>' + this.$t('message.aboutUsAntiCorruption4') + '</p>' +
            '<ul><li><p>' + this.$t('message.aboutUsAntiCorruption5') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption6') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption7') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption8') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption9') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption10') + '</p></li></ul>',
      'value_eng': '<p>' + this.$t('message.aboutUsAntiCorruption4') + '</p>' +
            '<ul><li><p>' + this.$t('message.aboutUsAntiCorruption5') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption6') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption7') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption8') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption9') + '</p></li>' +
             '<li><p>' + this.$t('message.aboutUsAntiCorruption10') + '</p></li></ul>'
      },
      id_title: 296,
      id_subTitle: 297,
      id_content: 298,
      textClass: 'contentAnticorruption',
      divClass: 'contentAnticorruption'
    }]
    let defaultTextContent1 = textContent1.map(cont => [cont['id_title'], cont['id_subTitle'], cont['id_content']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultTextContent1 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.textContent1[ind]['hidden'] = hiddenContent[ind]
        }
        this.showTextContent1 = true
      })
    let addedTextContent1 = []
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentTextTypeOfActivities'
    }).then(() => {
      addedTextContent1 = this.$store.getters['contentChanges/added_content_by_type']('ContentTextTypeOfActivities')
      this.addedTextContent1 = addedTextContent1
      this.loadedAddedTextContent1 = true
    })
    let defaultTextContent2 = textContent1.map(cont => [cont['id_title'], cont['id_subTitle'], cont['id_content']])
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': defaultTextContent2 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        for (let ind in hiddenContent) {
          this.textContent1[ind]['hidden'] = hiddenContent[ind]
        }
        this.showTextContent2 = true
      })
    let addedTextContent2 = []
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentTextTypeOfActivities'
    }).then(() => {
      addedTextContent2 = this.$store.getters['contentChanges/added_content_by_type']('ContentTextTypeOfActivities')
      this.addedTextContent2 = addedTextContent2
      this.loadedAddedTextContent2 = true
    })
    return {
      addedTextContent1: addedTextContent1,
      addedTextContent2: addedTextContent2,
      showTextContent1: false,
      loadedAddedTextContent1: false,
      showTextContent2: false,
      loadedAddedTextContent2: false,
      textContent1: textContent1,
      textContent2: textContent2,
      toggle_exclusive: 3
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    textContent1ToShow () {
      let showContent = []
      let textContent1ToShow = []
      if (this.isAuth) {
        textContent1ToShow = this.textContent1
      } else {
        textContent1ToShow = this.textContent1.filter(cont => cont['hidden'] === false)
      }
      for (let content of textContent1ToShow) {
        showContent.push(content)
      }
      for (let content of this.addedTextContent1) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['id_subTitle'] = this.get_field_id(content, 'subTitle')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    },
    textContent2ToShow () {
      let showContent = []
      let textContent2ToShow = []
      if (this.isAuth) {
        textContent2ToShow = this.textContent2
      } else {
        textContent2ToShow = this.textContent1.filter(cont => cont['hidden'] === false)
      }
      for (let content of textContent2ToShow) {
        showContent.push(content)
      }
      for (let content of this.addedTextContent1) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['id_subTitle'] = this.get_field_id(content, 'subTitle')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Антикоррупционная политика компании Фарм-Инновации, основные тезисы в отношении корупции и форма для отправки нам информации о случаях коррупции в компании'
      },
      { name: 'title',
        content: 'Антикоррупционная политика',
      }
    ]
  }
}
</script>
