<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="partnership">
          <partnership-submenu :toggle_none="this.toggle_exclusive"/>
      </v-row>
      <v-row v-if="showContentText || isAuth" class="partnership">
          <v-col cols="12" lg="12" md="12" sm="12">
              <content-text
                  :title="$t('message.partnershipSubmenu3')"
                  :content="$t('message.partnershipOurPartnersText')"
                  title-class="contentHeading"
                  sub-title-class="contentSubTitle"
                  text-class="contentText"
                  :hidden="!showContentText"
                  :id_title="394"
                  :id_sub-title="395"
                  :id_content="396"
              />
          </v-col>
      </v-row>
      <v-row v-if="loadedDefaultContent && loadedAddedContent" class="partnership">
          <v-col
              v-if="!content['hidden'] || isAuth"
              v-for="(content, index) in contentToShow" :key="index"
              cols="12" lg="3" md="4" sm="6">
              <card-logo
                  :id_path-img="content['id_pathImg']"
                  :path-img="content['pathImg']"
                  :id_link="content['id_link']"
                  :hidden="content['hidden']"
                  :added="content['added']"
              />
          </v-col>
      </v-row>
  <v-btn v-if="isAuth" @click="add_content">Добавить</v-btn>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import CardLogo from '../components/ui/CardLogo'
import { mdiChevronRight } from '@mdi/js'
import CardManagement from "@/components/ui/CardManagement.vue";
import ContentText from "@/components/ui/ContentText.vue";
export default {
  components: {ContentText, CardManagement, CardLogo },
  data () {
    let addedContent = []
    let defaultContent = [
      {
        pathImg: '/img/brand/Innovation_fund.jpeg',
        id_pathImg: 100,
        id_link: 398
      },
      {
        pathImg: '/img/brand/Alef.png',
        id_pathImg: 101,
        id_link: 399
      },
      {
        pathImg: '/img/brand/AND.png',
        id_pathImg: 102,
        id_link: 400
      },
      {
        pathImg: '/img/brand/BWell.png',
        id_pathImg: 103,
        id_link: 401
      },
      {
        pathImg: '/img/brand/Creyt.png',
        id_pathImg: 104,
        id_link: 402
      },
      {
        pathImg: '/img/brand/Lierac.png',
        id_pathImg: 105,
        id_link: 403
      },
      {
        pathImg: '/img/brand/Medi.png',
        id_pathImg: 106,
        id_link: 404
      },
      {
        pathImg: '/img/brand/Mega-optima.png',
        id_pathImg: 107,
        id_link: 405
      },
      {
        pathImg: '/img/brand/Omron.png',
        id_pathImg: 108,
        id_link: 406
      },
      {
        pathImg: '/img/brand/Pharm-group.png',
        id_pathImg: 109,
        id_link: 407
      },
      {
        pathImg: '/img/brand/PierreFabre.png',
        id_pathImg: 110,
        id_link: 407
      },
      {
        pathImg: '/img/brand/Renewal.png',
        id_pathImg: 111,
        id_link: 408
      },
      {
        pathImg: '/img/brand/Sintez.png',
        id_pathImg: 112,
        id_link: 409
      },
      {
        pathImg: '/img/brand/Solopharm.png',
        id_pathImg: 113,
        id_link: 410
      },
      {
        pathImg: '/img/brand/Vichy.png',
        id_pathImg: 114,
        id_link: 411
      },
      {
        pathImg: '/img/brand/Edge-Pharma.png',
        id_pathImg: 115,
        id_link: 412
      }
    ]
    let contentTextIdList = [[394, 395, 396]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': contentTextIdList })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.showContentText = !hiddenContent[0]
      })
    let letThis = this
    new Promise(function (resolve, reject) {
      let id_list = defaultContent.map(cont => [cont['id_pathImg']])
      letThis.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': id_list })
        .then(() => {
          let hidden_content = letThis.$store.getters['contentChanges/hidden_content']
          for (let ind in hidden_content) {
            letThis.defaultContent[ind]['hidden'] = hidden_content[ind]
          }
          letThis.loadedDefaultContent = true
        })
    })
    new Promise(function (resolve, reject) {
      letThis.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
        'type': 'CardLogo'
      }).then(() => {
        addedContent = letThis.$store.getters['contentChanges/added_content_by_type']('CardLogo')
        letThis.addedContent = addedContent
        letThis.loadedAddedContent = true
      })
    })
    return {
      showContentText: false,
      loadedAddedContent: false,
      loadedDefaultContent: false,
      defaultContent: defaultContent,
      addedContent: addedContent,
      toggle_exclusive: 2
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    contentToShow () {
      let showContent = []
      let defaultContentToShow = []
      if (this.isAuth) {
        defaultContentToShow = this.defaultContent
      }
      else {
        defaultContentToShow = this.defaultContent.filter(cont => cont['hidden'] === false)
      }
      for (let content of defaultContentToShow) {
        showContent.push(content)
      }
      for (let content of this.addedContent) {
        let newAddedContent = {}
        newAddedContent['id_pathImg'] = this.get_field_id(content,'pathImg')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'CardLogo',
        'field_list': ['pathImg']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      return addedContent['changes'].filter(content => content.field == field)[0]['id_content']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Информация об клиентах компании Фарм-Инновации, ссылки на их сайты' },
      { name: 'title',
        content: 'Партнеры' }
    ]
  }
}
</script>
