<template>
  <v-container fluid class="no-indentation mb-12">
    <v-row class="partnership">
      <partnership-submenu />
    </v-row>
    <v-row class="partnership">
      <v-col cols="12" lg="6" md="6" sm="12" class="partnership__content">
        <content-text
            v-if="show1 || isAuth"
            :title="$t('message.partnershipHead1')"
            :content="$t('message.sliderText1')"
            :id_content="305"
            :id_title="306"
            :id_sub-title="307"
            :hidden="!show1"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
        <content-text
            v-if="show2 || isAuth"
            :title="$t('message.partnershipHead2')"
            :content="$t('message.partnershipText24')"
            :id_content="308"
            :id_title="309"
            :id_sub-title="310"
            :hidden="!show2"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
        <content-text
            v-if="show3 || isAuth"
            :sub-title="$t('message.partnershipSubHead1')"
            :content="$t('message.partnershipText1')"
            :id_content="311"
            :id_title="312"
            :id_sub-title="313"
            :hidden="!show3"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
        />
        <content-frame
          div-class="partnershipAdvantage pa-0"
          frame-src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad2f3f2da121b706c2dc893813751a1801ff7539afcc9f3997eb07944bdbeb37c&amp;source=constructor"
          :id_frame-src="314"
        />
      </v-col>
      <v-col cols="12" lg="5" md="5" sm="12" offset-lg="1" offset-md="1" offset-sm="0">
        <content-text
          v-if="show4 || isAuth"
          :sub-title="$t('message.partnershipSubHead2')"
          :id_title="314"
          :id_sub-title="315"
          :id_content="316"
          :hidden="!show4"
          title-class="contentHeading"
          sub-title-class="contentSubTitle partnershipSubHeading pl-lg-1 pl-md-3 pl-sm-4 pl-4"
          text-class="contentText"
        />
        <div class="contentText pt-4">
          <content-image
            :img-src="$t('message.partnershipSchemeSRC')"
            :is_link="true"
            :id_img-src="317"
            img-class="partnershipImg"
          />
        </div>
        <div v-if="loadedAddedTextContent">
          <content-text v-for="(content, index) in textContentToShow" :key="index"
            :id_content="content['id_content']"
            :id_title="content['id_title']"
            :id_sub-title="content['id_subTitle']"
            :added="true"
            title-class="contentHeading"
            sub-title-class="contentSubTitle"
            text-class="contentText"
          />
        </div>
        <v-btn v-if="isAuth" @click="add_content"> Добавить текстовый блок</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import ContentFrame from "@/components/ui/ContentFrame.vue";
import ContentImage from "@/components/ui/ContentImage.vue";
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu'
import store from "@/store";
import ContentText from "@/components/ui/ContentText.vue";
export default {
  components: {ContentImage, ContentText, PartnershipSubmenu, ContentFrame },
  data () {
    let idList1 = [[305, 306, 307]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList1 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show1 = !hiddenContent[0]
      })
    let idList2 = [[308, 309, 310]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList2 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show2 = !hiddenContent[0]
      })
    let idList3 = [[311, 312, 313]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList3 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show3 = !hiddenContent[0]
      })
    let idList4 = [[314, 315, 316]]
    this.$store.dispatch('contentChanges/CHECK_HIDDEN_CONTENT_LIST', { 'id_list': idList4 })
      .then(() => {
        let hiddenContent = this.$store.getters['contentChanges/hidden_content']
        this.show4 = !hiddenContent[0]
      })
    let addedTextContent = []
    this.$store.dispatch('contentChanges/GET_ADDED_CONTENT', {
      'type': 'ContentTextPartnership'
    }).then(() => {
      addedTextContent = this.$store.getters['contentChanges/added_content_by_type']('ContentTextPartnership')
      this.addedTextContent = addedTextContent
      this.loadedAddedTextContent = true
    })
    return {
      addedTextContent: addedTextContent,
      loadedAddedTextContent: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false
    }
  },
  computed: {
    isAuth () {
      return this.$store.getters['adminAuth/checkAuth']
    },
    textContentToShow () {
      let showContent = []
      for (let content of this.addedTextContent) {
        let newAddedContent = {}
        newAddedContent['id_title'] = this.get_field_id(content, 'title')
        newAddedContent['id_subTitle'] = this.get_field_id(content, 'subTitle')
        newAddedContent['id_content'] = this.get_field_id(content, 'content')
        newAddedContent['added'] = true
        showContent.push(newAddedContent)
      }
      return showContent
    }
  },
  methods: {
    add_content: async function () {
      this.$store.dispatch('contentChanges/ADD_NEW_CONTENT', {
        'type': 'ContentTextPartnership',
        'field_list': ['title', 'subTitle', 'content']
      }).then(() => {
        let last_added_content = this.$store.getters['contentChanges/last_added_content']
        this.addedContent.push(last_added_content)
      })
    },
    get_field_id: function (addedContent, field) {
      let cont = addedContent['changes'].filter(content => content.field == field)[0]
      if (!cont) {
        return cont
      }
      return cont['id_content']
    }
  },
  metaInfo: {
    meta: [
      { name: 'description',
        content: 'Бизнес-модель и преимущества сотрудничества с компанией Фарм-Инновации'
      },
      { name: 'title',
        content: 'Партнерство' }
    ]
  }
}
</script>
